// @flow

import cryptoRandomString from 'crypto-random-string'
import isFQDN from 'validator/lib/isFQDN'
import matchAll from 'string.prototype.matchall'

export const ingressDomains /*: Array<string> */ = [
  'kubesail.io',
  'kubesail.net',
  'kubesail.org',
  'kubesail.xyz',
]

export const usableIngressDomains /*: Array<string> */ = [
  // 'kubesail.io', // Disabled on Mar 19, 2020 due to LE ratelimit
  'kubesail.net',
  'kubesail.org',
  'kubesail.xyz',
]

export const USERNAME_OR_NAMESPACE_MIN_LENGTH = 1

export function sampleArray(arr /*: Array<any> */, index = false) {
  if (!arr || !arr.length) return undefined
  if (index) return Math.floor(Math.random() * arr.length)
  return arr[Math.floor(Math.random() * arr.length)]
}

export function getRandomInt(min /*: number */, max /*: number */) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export function setPTimeout(ms /*: number */) /*: Promise<void> */ {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function hasOwnProperty(obj /*: Object */, key /*: string */) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export function localStorageEnabled() {
  if (typeof window === 'undefined' || !window?.localStorage) {
    return false
  } else {
    return true
  }
}

export function convertToClusterAddress(
  name /*: string */,
  username /*: string */,
  gateway /*: string */
) {
  name = name
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .replace(/^[-]+/, '')
    .replace(/[-]+$/, '')

  const result = `${name}.${username}.${gateway}`.toLowerCase()

  if (isFQDN(result)) return result
}

// from https://discordjs.guide/popular-topics/embeds.html#embed-limits
export function formatDiscordEmbed(embed /*: Object */) {
  function checkMsgLength(len, fieldToAdd) {
    if (len + fieldToAdd.length >= 6000) {
      embed.description = ('MSG TRUNCATED | ' + embed.description).substring(0, 2048)
      return false
    }
    return true
  }

  // Add title and description
  let msgLength = 0
  embed.title = embed.title && embed.title.substring(0, 256)
  embed.description = embed.description && embed.description.substring(0, 2048)
  msgLength += (embed.title || '').length + (embed.description || '').length

  // Add Fields
  if (Array.isArray(embed.fields)) {
    if (embed.fields.length > 25) {
      const len = embed.fields.length
      embed.fields = embed.fields.slice(0, 24)
      embed.fields.push({
        name: 'Oops! Message truncated...',
        value: `${len - 24} fields were omitted from this message due to Discord limits`,
      })
    }
    embed.fields = embed.fields
      .map(({ name, value }) => {
        if (!checkMsgLength(msgLength, name.substring(0, 256) + value.substring(0, 1024)))
          return false
        msgLength += name.length + value.length
        return {
          name: name.substring(0, 256),
          value: value.substring(0, 1024),
        }
      })
      .filter(Boolean)
  }

  // Add footer
  if (embed.footer && embed.footer.text) {
    if (checkMsgLength(msgLength, embed.footer.text.substring(0, 2048))) {
      embed.footer.text = embed.footer.text.substring(0, 2048)
      msgLength += embed.footer.text.length
    } else {
      return embed
    }
  }

  // Add author
  if (embed.author && embed.author.name) {
    if (checkMsgLength(msgLength, embed.author.name.substring(0, 2048))) {
      embed.author.name = embed.author.name.substring(0, 2048)
      msgLength += embed.author.name.length
    } else {
      return embed
    }
  }

  return embed
}

export const FREE_TIER_IMAGE_BLACKLIST = [
  'v2ray',
  'skuline99/v2test',
  'centos-xfce-vnc',
  'v2fly',
  'bysr123/sdodosndinv',
  'bbb8001/',
]

export const SHARED_TIER_IMAGE_BLACKLIST = [
  'bbb8001/',
  'worksg/oyzheylz',
  'kuanfinn/', // Bitcoin miner author
  'bclswl0827/',
  'xiaokaixuan/',
  'gungfu2012/',
  'bbsec3/',
  'keytouch/ssdocker',
  'ubuntux00/',
  'clearux01/',
  'kullex/',
  'lhlnew2014/',
  'byxiaopeng/',
  'linuxserver/rutorrent',
]

export function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export function uppercaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function findDoc(docs, kind = '', name = '') {
  return docs.find(doc => {
    if (!doc?.kind || !doc?.metadata?.name) {
      console.error('findDoc: document in store with no kind?', { doc })
    }
    return (
      (doc?.kind || '').toLowerCase() === kind.toLowerCase() &&
      (doc?.metadata?.name || '').toLowerCase() === name.toLowerCase()
    )
  })
}

export function findUnusedDocumentsInNamespace(
  docs /*: Array<Object> */,
  limit /*: Array<string> */ = ['ConfigMap', 'Secret', 'ReplicaSet']
) {
  const untargetedResources = []

  const mark = doc => untargetedResources.push({ name: doc.metadata.name, kind: doc.kind })

  for (const currentDoc of docs) {
    if (!limit.includes(currentDoc.kind)) continue

    switch (currentDoc.kind) {
      case 'ReplicaSet':
        // More than zero replicas is not safe to remove
        if (currentDoc?.spec?.replicas !== 0) continue
        // If there are no ownerReferences, it's safe to remove
        else if (!currentDoc?.metadata?.ownerReferences) {
          mark(currentDoc)
          continue
        } else if (
          // If the owner of this ReplicaSet has a different generation than this ReplicaSet, it's safe to remove this ReplicaSet
          currentDoc.metadata.ownerReferences.filter(ownerRef => {
            const owner = docs.find(
              ownerDoc =>
                ownerDoc?.metadata?.name === ownerRef.name && ownerDoc.kind === ownerRef.kind
            )
            return owner?.metadata?.generation !== currentDoc?.metadata?.generation
          })
        ) {
          mark(currentDoc)
          continue
        }
        break

      case 'ConfigMap':
      case 'Secret':
        // Only ever delete resources that have been applied by kubectl
        if (
          !currentDoc?.metadata?.managedFields?.find(managedField => {
            return managedField.manager === 'kubectl-client-side-apply'
          })
        ) {
          continue
        } else if (
          Object.keys(currentDoc?.metadata?.annotations || {}).find(k => {
            return ['control-plane.alpha.kubernetes.io/leader'].includes(k)
          }) ||
          (currentDoc?.metadata?.labels?.['app.kubernetes.io/managed-by'] &&
            currentDoc?.metadata?.labels?.['app.kubernetes.io/managed-by'] !== 'skaffold')
        ) {
          continue
        } else if (
          !docs
            .filter(doc => ['Deployment', 'Pod', 'StatefulSet', 'DaemonSet'].includes(doc.kind))
            .find(doc => {
              const spec = doc.kind === 'Pod' ? doc?.spec : doc?.spec?.template?.spec
              return (
                spec?.containers?.find(container => {
                  return (
                    container?.envFrom?.find(
                      envFrom =>
                        envFrom?.[`${lowercaseFirstLetter(currentDoc.kind)}Ref`]?.name ===
                        currentDoc?.metadata?.name
                    ) ||
                    container?.env?.find(env => {
                      return (
                        env?.valueFrom?.[`${lowercaseFirstLetter(currentDoc.kind)}KeyRef`]?.name ===
                        currentDoc?.metadata?.name
                      )
                    })
                  )
                }) ||
                spec?.volumes?.find(volume => {
                  const ref = volume?.[lowercaseFirstLetter(currentDoc.kind)]
                  return (
                    (ref?.name || ref?.[`${lowercaseFirstLetter(currentDoc.kind)}Name`]) ===
                    currentDoc?.metadata?.name
                  )
                })
              )
            })
        ) {
          mark(currentDoc)
          continue
        }
        break
    }
  }

  return untargetedResources
}

// Returns either a valid form of an image name or null if the image name was invalid
// Docker image name rules:
// An image name is made up of slash-separated name components, optionally prefixed by a registry hostname.
// The hostname must comply with standard DNS rules, but may not contain underscores. If a hostname is present,
// it may optionally be followed by a port number in the format :8080. If not present, the command uses Docker’s
// public registry located at registry-1.docker.io by default. Name components may contain lowercase letters, digits
// and separators. A separator is defined as a period, one or two underscores, or one or more dashes.
// A name component may not start or end with a separator.
// A tag name must be valid ASCII and may contain lowercase and uppercase letters, digits, underscores, periods and dashes.
// A tag name may not start with a period or a dash and may contain a maximum of 128 characters.
export function validateContainerImageName(imageAddr) /*: string|false */ {
  if (typeof imageAddr !== 'string') return false
  imageAddr = imageAddr.replace('https://', '').replace('http://', '')
  const numberOfSlashes = (imageAddr.match(/\//g) || []).length

  const parts = imageAddr.split('/')
  let domain = 'registry.docker.com'
  let user = 'library'
  let tag = ''

  if (numberOfSlashes === 2) {
    // Eg: domain/user/tag (full image name with registry)
    // Chop port number off if present
    if (isFQDN((parts[0] || '').split(':')[0])) {
      domain = parts[0]
    }
    user = parts[1]
    tag = parts[2]
  } else if (numberOfSlashes === 1) {
    // Eg: user/tag (docker hub)
    user = parts[0]
    tag = parts[1]
  } else if (numberOfSlashes === 0) {
    // Eg: tag (docker hub official image)
    tag = parts[0]
  }

  // If a username is present, it must be alphanumeric
  if (user && RegExp(/^[a-zA-Z0-9-_]+$/).test(user) === false) return false
  if (!tag) return false

  const validImageRegex = new RegExp(
    '^(([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9])\\.)*([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9])(:[0-9]+\\/)?(?:[0-9a-z-]+[/@])(?:([0-9a-z-]+))[/@]?(?:([0-9a-z-.]+))?(?::[a-z0-9_\\.-]+)?$'
  )
  const testName = [domain, user, tag].filter(Boolean).join('/')

  const validatedTag = validImageRegex.test(testName)
  if (!validatedTag) return false

  return imageAddr
}

export const EMAIL_INVITE_EXPIRE_TIME_SEC = 3 * 24 * 60 * 60

export const docDescriptions = {
  Deployment:
    'A Deployment is an application - it controls all aspects of how an app is "Deployed". From the container image, the number of replicas to launch, where and how it\'s launched and more - a Deployment is everything you need to know about a running application. A Deployment will create ReplicaSets and Pods which it manages.',
  Pod: 'A Pod is a single instance of a running App. A deployment may launch multiple pods - and a Pod may contain multiple containers - but a Pod is the lowest atomic unit of a running App.',
  Service:
    'A Service is a definition of the networking for an App - it will define the ports and protocols used by an application.',
  Ingress:
    'An Ingress is a definition of HTTP or HTTPS access which targets a Service. This resource is used to send traffic to the correct place based on its domain-name or URL path.',
  PersistentVolumeClaim:
    'A PersistentVolumeClaim is a request for storage. A storage system will read this resource and create a PersistentVolume, which is a representation of an actual blob of storage on a particular disk on a particular system.',
}

export const agentRecommendedFeatures = [
  // {
  //   friendlyName: 'Monitoring',
  //   description:
  //     'A bundle of monitoring software including: metrics-server, prometheus, and node_exporter. Enabling monitoring will enable a number of features on the KubeSail.com dashboard like heads-up display, storage management, and usage graphs.',
  //   featureName: 'metrics',
  //   templates: [{ username: 'erulabs', name: 'monitoring' }],
  //   required: false,
  // },
  // {
  //   friendlyName: 'Ingress Controller',
  //   description:
  //     "An Ingress Controller is software which enables your server to receive web traffic. Without one, your web-apps won't be reachable. We recommend Traefik, but Nginx-Ingress is also supported.",
  //   featureName: 'ingressController',
  //   templates: [],
  //   helm: 'traefik/traefik',
  //   required: true,
  // },
  {
    friendlyName: 'Cert Manager',
    description:
      "Cert-Manager by Let's Encrypt allows your cluster to generate valid HTTPS certificates for your apps, for free! This feature is only required when using custom domains for your apps, otherwise, KubeSail will take care of certificates for your built-in domains automatically.",
    featureName: 'certManager',
    templates: [{ username: 'erulabs', name: 'cert-manager' }],
    helm: 'cert-manager/cert-manager',
    required: false,
  },
]

export const REGEX_FIND_TEMPLATE_VARIABLES = /\{\{\s?([a-z0-9-_]+)(\(.*\))?(\|[^}]+)?\s?\}\}/gi
export const REGEX_REPLACE_TEMPLATE_VARIABLES = varName => {
  // eslint-disable-next-line
  return new RegExp(`{{\\s?${varName}(|[^}]+)?\\s?}}`, 'g')
}
export const SRANDOM = function (type = 'url-safe') {
  return async function (args) {
    const length = parseInt(args[0], 10) || 32
    return cryptoRandomString({ length, type })
  }
}

export const kubeSailVariableFunctions = {
  HTACCESS_USERNAME: async function (_args, _variables, d) {
    return Buffer.from(d.username || '').toString('base64')
  },
  HTACCESS_PASSWORD: async function (_args, _variables, d) {
    return Buffer.from(d.password || '').toString('base64')
  },
  RANDOM: SRANDOM('url-safe'),
  SRANDOM: SRANDOM('json-safe'),
  HEX: SRANDOM('hex'),
  HTACCESS_AUTH: async function (_args, _variables, d) {
    if (d && d?.username && d?.password) {
      const bcrypt = await import('bcryptjs')
      const hash = bcrypt.hashSync(d.password, 13)
      return `${window.btoa(d.username + ':' + hash)}`
    } else {
      return ''
    }
  },
  TZ: async function (_args) {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (err) {
      console.error('Unable to determine timezone')
      return ''
    }
  },
}

export async function findKubeSailVariablesInYaml(yaml, existingVariables) {
  const variables = [...matchAll(yaml, REGEX_FIND_TEMPLATE_VARIABLES)]
  const renderedVariables = []
  for (const v of variables) {
    const parts = (v[3] || '')
      .replace(/^\|/, '')
      .replace(/\n/, ' ')
      .replace(/\s\s+/i, ' ')
      .trim()
      .split('|')
    const d = {
      name: v[1],
      default: parts[0],
      description: parts[1],
      friendly: parts[2],
      rendered: undefined,
      userRequired: false,
      userVisible: true,
      allowEmpty: (parts[3] || '').includes('allowEmpty'),
    }
    if (renderedVariables.find(k => k.name === d.name)) continue
    if (d.name.startsWith('KS_')) continue
    let func = kubeSailVariableFunctions[d.name]
    let match = (v[0] || '').match(/^(.*)\((.*)\)/)

    if (func) {
      d.func = d.name
    } else {
      match = (d.default || '').match(/^(.*)\((.*)\)/)
      if (match) {
        func = kubeSailVariableFunctions[match[1]]
        d.func = match[1]
      }
    }

    if (func) {
      d.funcArgs = (match?.[2] || '').split(',').map(s => s.trim())
      if (d.name.startsWith('HTACCESS_')) {
        const formData = existingVariables.find(e => e.name === 'HTACCESS_AUTH')
        d.username = formData?.usernameInput || formData?.username || d.username
        d.password = formData?.passwordInput || formData?.password || d.password
        if (d.name !== 'HTACCESS_AUTH') d.userVisible = false
      }
      const existing = existingVariables.find(e => e.name === d.name)
      if (existing && existing.rendered) {
        d.rendered = existing.rendered
        d.value = existing.value
      } else {
        d.rendered = await func(d.funcArgs, existingVariables, d)
      }
      d.default = null
    }
    renderedVariables.push(d)
  }
  return renderedVariables
}

// Given a Kubernetes-client and a Kubernetes document, find the correct API object for this document.
// Does not include the doc.metadata.name, ie:
//   const api = getK8sAPIForDoc(client, Deployment)
//   api.post()
//   api(deploymentName).delete()
export function getK8sAPIForDoc(client, doc, watch = false) {
  const split = doc.apiVersion.split('/')
  const group = split.length > 1 ? split[0] : ''
  const version = split.length === 1 ? split[0] : split[1]
  const base = group ? client.apis[group] : client.api
  let baseApi = base[version]
  if (version === 'v1beta1' && !base[version] && base.v1) {
    baseApi = base.v1
  }
  if (!baseApi) {
    console.error('getK8sAPIForDoc: no baseApi', {
      apiVersion: doc.apiVersion,
      name: doc.metadata.name,
      namespace: doc.metadata.namespace,
      kind: doc.kind,
      keys: Object.keys(base?.[version]?.[doc.kind.toLowerCase()] || {}),
    })
    return null
  }
  if (watch) baseApi = baseApi.watch
  const isNamespaced = doc.metadata.namespace && typeof baseApi.namespaces === 'function'
  const scopedApi = isNamespaced
    ? baseApi.namespaces(doc.metadata.namespace || doc.metadata.name)
    : baseApi
  const convertedKind = doc.kind.toLowerCase().replace(/y$/, 'ie')
  const api = scopedApi[convertedKind] ? scopedApi[convertedKind] : baseApi[convertedKind]
  if (!api) {
    // eslint-disable-next-line
    console.error('getK8sAPIForDoc: no api', {
      apiVersion: doc.apiVersion,
      name: doc.metadata.name,
      namespace: doc.metadata.namespace,
      kind: doc.kind,
      key: `client.${group ? `apis["${group}"]` : `api`}${
        isNamespaced
          ? `.${version}.namespaces(${doc.metadata.namespace || doc.metadata.name})`
          : `.${version}`
      }.${convertedKind}`,
    })
  }
  return api
}

export const kubernetesGlossary = {
  MutatingWebhookConfiguration:
    'Admission webhooks are HTTP callbacks that receive admission requests and do something with them. You can define two types of admission webhooks, validating admission webhook and mutating admission webhook. Mutating admission webhooks are invoked first, and can modify objects sent to the API server to enforce custom defaults.',
  ValidatingWebhookConfiguration:
    'Admission webhooks are HTTP callbacks that receive admission requests and do something with them. You can define two types of admission webhooks, validating admission webhook and mutating admission webhook. Mutating admission webhooks are invoked first, and can modify objects sent to the API server to enforce custom defaults.',
  Role: 'An RBAC Role or ClusterRole contains rules that represent a set of permissions. Permissions are purely additive (there are no "deny" rules).',
  ClusterRole:
    'Defines a set of resource types and operations that can be assigned to a user or group of users in a cluster (ClusterRole), or a Namespace (Role), but does not specify the user or group of users.',
  ClusterRoleBinding:
    'A role binding grants the permissions defined in a role to a user or set of users. It holds a list of subjects (users, groups, or service accounts), and a reference to the role being granted. A RoleBinding grants permissions within a specific namespace whereas a ClusterRoleBinding grants that access cluster-wide.',
  RoleBinding:
    'A role binding grants the permissions defined in a role to a user or set of users. It holds a list of subjects (users, groups, or service accounts), and a reference to the role being granted. A RoleBinding grants permissions within a specific namespace whereas a ClusterRoleBinding grants that access cluster-wide.',
  CustomResourceDefinition:
    'The CustomResourceDefinition API resource allows you to define custom resources. Defining a CRD object creates a new custom resource with a name and schema that you specify.',
  Cluster:
    'A set of worker machines, called nodes, that run containerized applications. Every cluster has at least one worker node.',
  DaemonSet: 'Ensures a copy of a Pod is running across a set of nodes in a cluster.',
  'Data Plane':
    'The layer that provides capacity such as CPU, memory, network, and storage so that the containers can run and connect to a network. ',
  Deployment:
    'An API object that manages a replicated application, typically by running Pods with no local state.',
  'Device Plugin':
    'Device plugins run on worker Nodes and provide Pods with access to resources, such as local hardware, that require vendor-specific initialization or setup steps.',
  LimitRange:
    'Provides constraints to limit resource consumption per Containers or Pods in a namespace.',
  Namespace:
    'An abstraction used by Kubernetes to support multiple virtual clusters on the same physical cluster.',
  Node: 'A node is a worker machine in Kubernetes.',
  Pod: 'The smallest and simplest Kubernetes object. A Pod represents a set of running containers on your cluster.',
  'Pod Lifecycle': 'The sequence of states through which a Pod passes during its lifetime.',
  'Pod Security Policy': 'Enables fine-grained authorization of Pod creation and updates.',
  'QoS Class':
    'QoS Class (Quality of Service Class) provides a way for Kubernetes to classify Pods within the cluster into several classes and make decisions about scheduling and eviction.',
  'RBAC (Role-Based Access Control)':
    'Manages authorization decisions, allowing admins to dynamically configure access policies through the Kubernetes API.',
  ReplicaSet: 'A ReplicaSet (aims to) maintain a set of replica Pods running at any given time.',
  'Resource Quotas':
    'Provides constraints that limit aggregate resource consumption per Namespace.',
  Service:
    'An abstract way to expose an application running on a set of Pods as a network service.',
  ServiceAccount: 'Provides an identity for processes that run in a Pod.',
  'shuffle sharding':
    'A technique for assigning requests to queues that provides better isolation than hashing modulo the number of queues.',
  StatefulSet:
    'Manages the deployment and scaling of a set of Pods, and provides guarantees about the ordering and uniqueness of these Pods.',
  'Static Pod': 'A pod managed directly by the kubelet daemon on a specific node,',
  Volume: 'A directory containing data, accessible to the containers in a Pod.',
}
